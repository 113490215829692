import React, { useState, useEffect } from 'react';
import './App.css';
import { useStyletron } from 'baseui';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import useReactFontLoader from 'react-font-loader';
import { LightTheme, BaseProvider } from 'baseui';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toaster, Spinner } from 'evergreen-ui';
import { Grid, Link, Typography, Paper, Container, Popover, MenuItem, Button as MButton } from "@material-ui/core";
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import CssBaseline from "@material-ui/core/CssBaseline";
import logoFull from "./assets/wunderDB-Full-with-Box.png";
import logoSmall from "./assets/wdot-dol.png";
import rgBlue from "./assets/registration-blue.svg";
import rgRed from "./assets/registration-red.svg";
import rgYellow from "./assets/registration-yellow.svg";
import rgGreen from "./assets/registration-green.svg";
import rgPurple from "./assets/registration-purple.svg";
import lgBlue from './assets/login-graphics-blue.svg';
import lgRed from './assets/login-graphics-red.svg';
import lgYellow from './assets/login-graphics-yellow.svg';
import lgGreen from './assets/login-graphics-green.svg';
import lgPurple from './assets/login-graphics-purple.svg';
import { faChevronLeft, faChevronRight, faCubes, faCopy, faUser, faLock, faAt, faLayerGroup, faChevronDown, faChevronUp, faBook, faAsterisk, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Alert } from 'baseui/icon';
import { Button } from "baseui/button";
import { validate as validateEmail } from 'email-validator';

const engine = new Styletron();

function Negative() {
	const [css, theme] = useStyletron();
	return (
		<div
			className={css({
				display: 'flex',
				alignItems: 'center',
				paddingRight: theme.sizing.scale500,
				color: theme.colors.negative400,
			})}
		>
			<Alert size="18px" />
		</div>
	);
}


function App() {

	let theme = createTheme({
		palette: {
			type: 'light',
		},
	});

	useReactFontLoader({
		url:
			"https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap&family=Quicksand:wght@300;400;500;600;700&display=swap",
	});

	const colors = {
		blue: {
			backgroundColor: "#6398F9",
			foregroundColor: "#f2f3ff",
			accentColor: "#2196f3",
			registrationGraphics: rgBlue,
			loginGraphics: lgBlue
		},
		red: {
			backgroundColor: "#b10041",
			foregroundColor: "#fff0f3",
			accentColor: "#AA0023",
			registrationGraphics: rgRed,
			loginGraphics: lgRed
		},
		green: {
			backgroundColor: "#009a76",
			foregroundColor: "#f7fff4",
			accentColor: "#3faf62",
			registrationGraphics: rgGreen,
			loginGraphics: lgGreen
		},
		yellow: {
			backgroundColor: "#f1a700",
			foregroundColor: "#fffbe6",
			accentColor: "#ff9800",
			registrationGraphics: rgYellow,
			loginGraphics: lgYellow
		},
		purple: {
			backgroundColor: "#7d00dc",
			foregroundColor: "#f9f0ff",
			accentColor: "#6a5ce9",
			registrationGraphics: rgPurple,
			loginGraphics: lgPurple
		}
	}

	const [selectedColor, setSelectedColor] = useState(colors.blue);
	const [expandColorPicker, setExpandColorPicker] = useState(false);
	const [registrationPanelStatus, setRegistrationPanelStatus] = useState('Ready');
	const [isSetOTPPanel, setIsSetOTPPanel] = React.useState(false);

	const [name, setName] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [otp, setOtp] = useState("");
	const [isValid, setIsValid] = React.useState(false);
	const [isVisited, setIsVisited] = React.useState(false);
	const shouldShowError = !isValid && isVisited;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [process, setProcess] = useState("Ready");

	const resetDetails = () => {
		setName("");
		setUsername("");
		setPassword("");
		setOtp("");
		setIsSetOTPPanel(false);
		setRegistrationPanelStatus("Active");
		setProcess('Ready');
	}

	const pickColor = (color) => {
		setSelectedColor(colors[color]);
		setExpandColorPicker(false);
	}

	const onChange = ({ target: { value } }) => {
		setIsValid(validateEmail(value));
		setUsername(value);
	};

	function sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	const getOTP = () => {
		setProcess('Processing');
		fetch("https://wdb.tanmoysg.com/register/authenticate", {
			method: "POST",
			cache: "no-cache",
			headers: {
				"content_type": "application/json",
			},
			body: JSON.stringify({
				"username": username
			})
		}).then(response => {
			return response.json()
		}).then(json => {
			if (json.status_code === '0') {
				toaster.danger(json.response);
				setProcess('Processed');
			} else {
				toaster.success(json.response);
				setIsSetOTPPanel(true);
				setProcess('Processed');
			}
		})
	}

	const verifyOTP = () => {
		setProcess('Processing');
		fetch("https://wdb.tanmoysg.com/register/verify", {
			method: "POST",
			cache: "no-cache",
			headers: {
				"content_type": "application/json",
			},
			body: JSON.stringify({
				"username": username,
				"name": name,
				"password": password,
				"otp": otp
			})
		}).then(response => {
			return response.json()
		}).then(json => {
			if (json.status_code === '0') {
				toaster.danger(json.response);
				setProcess('Processed');
			} else {
				toaster.success(json.response);
				setRegistrationPanelStatus("Success");
				sleep(30000).then(() => { resetDetails() });
				setProcess('Processed');
			}
		})
	};

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Container maxWidth="lg">
				<Grid container direction="column" justifyContent="center" alignItems="center" style={{ height: "100%", width: "100%" }}>
					<Grid item style={{ width: "100%", height: "10vh" }}>
						<div style={{ padding: "25px 10px" }}>
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item style={{ height: "100%" }}>
									<Grid container direction="row" alignItems="center">
										<img src={logoSmall} style={{ height: "6.5vh", marginRight: "5px" }} />
									</Grid>
								</Grid>
								<Grid item style={{ height: "100%" }}>
									<Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
										<Link variant="h5" underline="none" href="https://github.com/TanmoySG/wunderDB" target="_blank" style={{ float: "right", color: "#26242F" }}><FontAwesomeIcon icon={faGithub} style={{ color: "#26242F" }} /></Link>
										<Paper variant="outlined" style={{ marginLeft: "5px", padding: "5px", borderRadius: "35px" }} onClick={(e) => { setExpandColorPicker(!expandColorPicker); setAnchorEl(e.currentTarget); }} >
											<Grid container direction="row" alignItems="center" >
												{
													expandColorPicker ?
														<FontAwesomeIcon icon={faChevronUp} style={{ marginLeft: "5px", marginRight: "5px" }} />
														: <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: "5px", marginRight: "5px" }} />
												}
												<div style={{ height: "25px", width: "25px", borderRadius: "100%", backgroundColor: selectedColor.accentColor, margin: "0 0 0 3px" }} />
											</Grid>
										</Paper>
										<Popover
											id="simple-menu"
											anchorEl={anchorEl}
											keepMounted
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}}
											open={expandColorPicker}
											onClose={() => { setExpandColorPicker(false) }}
										>
											{
												Object.keys(colors).map((color) => {
													return (
														<MenuItem onClick={() => { pickColor(color) }}>
															<div style={{ height: "25px", width: "25px", borderRadius: "100%", backgroundColor: colors[color].accentColor, margin: "0 3px" }} />
														</MenuItem>
													)
												})
											}
										</Popover>
									</Grid>
								</Grid>
							</Grid>
						</div>
					</Grid>
					<Grid item style={{ padding: "25px 10px", width: "100%", height: "100%" }}>
						<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={5}>
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<img src={logoFull} style={{ height: "7.5vh", marginRight: "5px" }} />
								<div style={{ margin: "25px 0" }}>
									<Typography style={{ fontFamily: "Work Sans", fontSize: "1.5rem", fontWeight: "400", color: "black" }}>
										<span style={{ color: selectedColor.accentColor, fontWeight: "500", borderRadius: "7px", border: "1px solid " + selectedColor.accentColor, padding: "5px 7px" }} >wunderDB</span> is a tiny JSON-based Document Datastore, inspired by MongoDB. wDB is the perfect choice for budding-devs and projects.
									</Typography>
									<br /><br />
									<Typography style={{ fontFamily: "Work Sans", fontSize: "1.5rem", fontWeight: "400", color: "black" }}>
										Powered by<span style={{ margin: "0 5px", borderRadius: "7px", border: "1px solid " + selectedColor.accentColor, padding: "5px 7px" }}>Unified Access Endpoint</span>, wDB Clusters are cloud hosted and accessible from anywhere, on any device.
									</Typography>
									<br /><br />
									<Typography style={{ fontFamily: "Work Sans", fontSize: "1.5rem", fontWeight: "400", color: "black" }}>
										Learn more about wunderDB on the Documentation and deep-dive the code on Github.
									</Typography>
								</div>
								<div style={{ margin: "25px 0" }}>
									<MButton variant="contained" size="large" style={{ margin: "5px 10px 5px 0", fontFamily: "Work Sans", borderRadius: "5px", /*boxShadow: "rgb(161 161 161 / 17%) 0px 0px 14px 1px",*/ backgroundColor: selectedColor.foregroundColor, border: "1px solid " + selectedColor.accentColor, fontFamily: "Work Sans" }} href="https://github.com/TanmoySG/wunderDB/blob/master/documentation/documentation.md">
										<FontAwesomeIcon icon={faBook} style={{ marginRight: "5px", color: selectedColor.accentColor }} />
										Documentation
									</MButton>
									<MButton variant="contained" size="large" style={{ margin: "5px 10px 5px 0", fontFamily: "Work Sans", borderRadius: "5px", /*boxShadow: "rgb(161 161 161 / 17%) 0px 0px 14px 1px",*/ backgroundColor: selectedColor.foregroundColor, border: "1px solid " + selectedColor.accentColor, fontFamily: "Work Sans" }} href="https://github.com/TanmoySG/wunderDB/">
										<FontAwesomeIcon icon={faGithub} style={{ marginRight: "5px", color: selectedColor.accentColor }} />
										Code Repository
									</MButton>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Paper variant="outlined" style={{/* height: "100vh", */ boxShadow: "rgb(161 161 161 / 17%) 0px 0px 14px 1px", padding: "30px", borderRadius: "15px", backgroundColor: selectedColor.foregroundColor }}>
									{
										registrationPanelStatus === 'Ready' ?
											<center>
												<div onClick={() => { setRegistrationPanelStatus("Active") }} style={{ cursor: "pointer" }}>
													<img src={selectedColor.registrationGraphics} style={{ width: "75%", fill: selectedColor.accentColor }} />
													<Typography style={{ fontFamily: "Work Sans", fontSize: "1.5rem", lineHeight: "1", fontWeight: "400", color: selectedColor.backgroundColor }}>
														Create an Account
													</Typography>
												</div>
												<div style={{ margin: "20px 0" }}>
													<Grid container direction="row" justifyContent="space-between" alignItems="center">
														<hr style={{ border: 0, background: selectedColor.accentColor, width: "40%", height: "1.5px" }} />
														<span style={{ fontFamily: "DM Sans", color: selectedColor.backgroundColor, fontWeight: "600", fontSize: "1rem" }}>OR</span>
														<hr style={{ border: 0, background: selectedColor.accentColor, width: "40%", height: "1.5px" }} />
													</Grid>
												</div>
												<Link underline="none" href="https://wdb.tanmoysg.com/wdash" target="_blank">
													<div style={{ margin: "0px 0 25px 0" }} >
														<img src={selectedColor.loginGraphics} style={{ width: "50%", fill: selectedColor.accentColor }} />
														<div style={{ marginTop: "5px" }}>
															<Typography style={{ fontFamily: "Work Sans", fontSize: "1.25rem", fontWeight: "400", color: selectedColor.backgroundColor }}>
																Login with
																<span style={{ marginLeft: "5px", fontFamily: "Work Sans", fontWeight: "400", backgroundColor: selectedColor.backgroundColor, color: selectedColor.foregroundColor, borderRadius: "7px", padding: "7.5px 10px" }} >
																	wunderDash
																</span>
															</Typography>
														</div>
													</div>
												</Link>
											</center>
											: registrationPanelStatus === 'Active' ?
												<>
													<Typography style={{ fontFamily: "DM Sans", fontSize: "1.5rem", lineHeight: "1", fontWeight: "400", color: "black" }}>
														Create an Account
													</Typography>
													<StyletronProvider value={engine}>
														<BaseProvider theme={LightTheme}>
															<div style={{ margin: "30px 0 30px 0" }}>
																<FormControl label="Name" style={{ fontFamily: "Work Sans", fontSize: "1rem" }}
																	overrides={{
																		Label: {
																			style: {
																				fontFamily: "Work Sans", fontSize: "1rem"
																			}
																		}
																	}}
																>
																	<Input startEnhancer={<FontAwesomeIcon icon={faUser} />} onChange={e => setName(e.target.value)}
																		overrides={{
																			Root: {
																				style: {
																					borderColor: selectedColor.backgroundColor,
																					backgroundColor: "transparent"
																				}
																			},
																			StartEnhancer: {
																				style: {
																					color: selectedColor.backgroundColor,
																					backgroundColor: "transparent"
																				}
																			},
																			Input: {
																				style: {
																					backgroundColor: "transparent"
																				}
																			},
																			InputContainer: {
																				style: {
																					backgroundColor: "transparent"
																				}
																			}
																		}}
																	/>
																</FormControl>
															</div>
															<div style={{ margin: "30px 0" }}>
																<FormControl label="Email" style={{ fontFamily: "Work Sans", fontSize: "1rem" }} error={shouldShowError ? 'Please input a valid email address' : null}
																	overrides={{
																		Label: {
																			style: {
																				fontFamily: "Work Sans", fontSize: "1rem"
																			}
																		}
																	}}
																>
																	<Input startEnhancer={<FontAwesomeIcon icon={faAt} />} onChange={onChange} onBlur={() => setIsVisited(true)} error={shouldShowError} overrides={{
																		After: shouldShowError ? Negative : undefined,
																		Root: {
																			style: {
																				borderColor: selectedColor.backgroundColor,
																				backgroundColor: "transparent"
																			}
																		},
																		StartEnhancer: {
																			style: {
																				color: selectedColor.backgroundColor,
																				backgroundColor: "transparent"
																			}
																		},
																		Input: {
																			style: {
																				backgroundColor: "transparent"
																			}
																		},
																		InputContainer: {
																			style: {
																				backgroundColor: "transparent"
																			}
																		}
																	}}
																		type="email" required />
																</FormControl>
															</div>
															<div style={{ margin: "30px 0" }}>
																<FormControl label="Password"
																	overrides={{
																		Label: {
																			style: {
																				fontFamily: "Work Sans", fontSize: "1rem"
																			}
																		}
																	}}
																>
																	<Input startEnhancer={<FontAwesomeIcon icon={faLock} />} type="password" onChange={e => setPassword(e.target.value)}
																		overrides={{
																			Root: {
																				style: {
																					borderColor: selectedColor.backgroundColor,
																					backgroundColor: "transparent"
																				}
																			},
																			StartEnhancer: {
																				style: {
																					color: selectedColor.backgroundColor,
																					backgroundColor: "transparent"
																				}
																			},
																			Input: {
																				style: {
																					backgroundColor: "transparent"
																				}
																			},
																			InputContainer: {
																				style: {
																					backgroundColor: "transparent"
																				}
																			}
																		}}
																	/>
																</FormControl>
															</div>
															{
																isSetOTPPanel ?
																	<div style={{ margin: "30px 0" }}>
																		<FormControl label="Verify your Mail" style={{ fontFamily: "Work Sans", fontSize: "1rem" }} error={shouldShowError ? 'Please input a valid email address' : null}
																			overrides={{
																				Label: {
																					style: {
																						fontFamily: "Work Sans", fontSize: "1rem"
																					}
																				}
																			}}
																		>
																			<Input startEnhancer={<FontAwesomeIcon icon={faAsterisk} />} onChange={e => setOtp(e.target.value)}
																				overrides={{
																					Root: {
																						style: {
																							borderColor: selectedColor.backgroundColor,
																							backgroundColor: "transparent"
																						}
																					},
																					StartEnhancer: {
																						style: {
																							color: selectedColor.backgroundColor,
																							backgroundColor: "transparent"
																						}
																					},
																					Input: {
																						style: {
																							backgroundColor: "transparent"
																						}
																					},
																					InputContainer: {
																						style: {
																							backgroundColor: "transparent"
																						}
																					}
																				}}
																			/>
																		</FormControl>
																	</div> : undefined
															}
															<Grid container direction="row">
																<Button preventDefault onClick={isSetOTPPanel ? verifyOTP : getOTP} style={{ fontFamily: "DM Sans", fontWeight: "400", backgroundColor: "#C3DFFF", color: "#395a7f", padding: "10px", borderRadius: "5px" }}> {isSetOTPPanel ? "Verify and Create" : "Generate OTP"}</Button>
																{process === 'Processing' ? <Spinner size={24} /> : undefined}
															</Grid>
														</BaseProvider>
													</StyletronProvider>
												</>
												: registrationPanelStatus === 'Success' ?
													<center>
														<FontAwesomeIcon icon={faCheck} style={{ fontSize: "3.5rem", lineHeight: "1", fontWeight: "400", color: "green" }} />
														<Typography style={{ fontFamily: "DM Sans", fontSize: "1.5rem", lineHeight: "1", fontWeight: "400", color: "black" }}>
															Cluster Created!
														</Typography>
														<Link underline="none" href="https://wdb.tanmoysg.com/wdash" target="_blank">
															<div style={{ marginTop: "25px" }}>
																<Typography style={{ fontFamily: "Work Sans", fontSize: "1rem", fontWeight: "400", color: selectedColor.backgroundColor }}>
																	Login with
																	<span style={{ margin: "0 5px", fontFamily: "Work Sans", fontWeight: "400", backgroundColor: selectedColor.backgroundColor, color: selectedColor.foregroundColor, borderRadius: "7px", padding: "7.5px 10px" }} >
																		wunderDash
																	</span>
																	to view your Cluster Configuration.
																</Typography>
															</div>
														</Link>
													</center> : undefined
									}
								</Paper>
								<Typography style={{ fontFamily: "Work Sans", fontSize: "1rem", fontWeight: "400", color: "black", float: "right", marginTop: "10px" }}>
									A Project by <Link underline="none" href="https://tanmoysg.com" target="_blank" style={{ fontFamily: "Work Sans", color: selectedColor.backgroundColor }}>Tanmoy Sen Gupta</Link>
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider >
	);
}

export default App;
